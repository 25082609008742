import { currentUserVar, tokenVar } from "../state/variables/auth"
import { listUgcSkipVar } from "../state/variables/backdropData"

const typePolicies = {
  Query: {
    fields: {
      currentUser: {
        read() {
          return currentUserVar()
        },
      },
      token: {
        read() {
          return tokenVar()
        },
      },
      listUgcSkip: {
        read() {
          return listUgcSkipVar()
        },
      },
    },
  },
}

export default typePolicies
