import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import fetch from "isomorphic-fetch"
import store from "store"
import typePolicies from "./typePolicies"

const isBrowser = typeof window !== "undefined"

const getToken = () => {
  return isBrowser
    ? store.get("token")
      ? store.get("token").replace(/['"]+/g, "")
      : null
    : null
}

const client = new ApolloClient({
  cache: new InMemoryCache({ typePolicies }),
  link: new HttpLink({
    fetch,
    uri: process.env.GATSBY_BACKDROP_API_URL,
    headers: getToken() ? { authorization: `Bearer ${getToken()}` } : {},
  }),
  resolvers: {
    Ugc: {
      _deleted: ugc => Boolean(ugc._deleted),
    },
  },
})

export default client
